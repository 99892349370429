import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { HomeLayout, ListsContainer } from './Home.styled'
import CreateListCard from '../../components/CreateListCard'
import Title from '../../components/Title'
import ListCard from '../../components/ListCard'
import { useHistory } from 'react-router'
import useSubscribeToUserLists from '../../firebaseServices/listManagement/useSubscribeToUserLists'
import { Empty, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { updateUserListsNames } from '../../firebaseServices/listManagement'

const HomePage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { user } = useSelector((state) => state)
  const { lists, loadingLists } = useSubscribeToUserLists(user.uid)
  const hasLists = !!lists.length

  const redirectToListEdit = (listKey) => {
    history.push(`listedit/${listKey}`)
  }

  useEffect(() => {
    updateUserListsNames(user.uid)
  }, [user])

  return (
    <HomeLayout>
      <Title text={t('myLists')} />
      <CreateListCard />
      <Spin
        style={{ height: '50vh' }}
        indicator={
          <LoadingOutlined spin style={{ fontSize: '3rem' }} />
        }
        spinning={loadingLists && !lists.length}
      >
        <ListsContainer>
          {hasLists ? (
            lists.map(({ key, name }) => (
              <ListCard
                key={key}
                name={name}
                onEdit={() => redirectToListEdit(key)}
              />
            ))
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('nothingHere')}
            />
          )}
        </ListsContainer>
      </Spin>
    </HomeLayout>
  )
}

export default HomePage

import styled from 'styled-components'
import { AnimatedSwitch } from 'react-router-transition'

export const CustomSwitch = styled(AnimatedSwitch).attrs(() => ({
  atEnter: { opacity: 0 },
  atLeave: { opacity: 0 },
  atActive: { opacity: 1 },
  className: 'switch-wrapper',
}))`
  & > div[style='opacity: 1;'] {
    position: sticky;
  }
`

import HomePage from '../Pages/Home'
import CreateList from '../Pages/CreateList'
import EditList from '../Pages/EditList'

const routes = [
  {
    path: 'home',
    component: HomePage,
    exact: true,
  },
  {
    path: 'createlist',
    component: CreateList,
    exact: true,
  },
  {
    path: 'listedit/:listId',
    component: EditList,
    exact: true,
  },
]

export default routes

import styled from 'styled-components'

export const PrivateSectionLayout = styled.div`
  min-height: 100%;
  padding: 2rem;
`

export const AvatarSection = styled.div`
  position: sticky;
  top: 2rem;
  left: 0;
  width: 100%;
  text-align: right;
  height: 0;
  z-index: 5;
`

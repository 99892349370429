import React, { forwardRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ItemLayout, BoughtCheckbox } from './ListItem.styled'

const ListItem = forwardRef(({ item, bought, onChange }, ref) => {
  const [checkboxState, setCheckboxState] = useState(bought)

  const handleCheckboxChange = ({ target }) => {
    setCheckboxState(target.checked)
    onChange(target.checked)
  }

  useEffect(() => {
    setCheckboxState(bought)
  }, [bought])

  return (
    <ItemLayout ref={ref} bought={bought}>
      {item}
      <BoughtCheckbox
        checked={checkboxState}
        defaultChecked={bought}
        onChange={handleCheckboxChange}
      />
    </ItemLayout>
  )
})

export default ListItem

ListItem.propTypes = {
  item: PropTypes.string.isRequired,
  bought: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

import styled from 'styled-components'

export const TitleText = styled.h1`
  z-index: 2;
  margin: 0;
  font-weight: bold;
  position: sticky;
  top: 2rem;
  pointer-events: none;
`

export const TitleMask = styled.div`
  z-index: 1;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  padding: 3.5rem;
  width: 100vw;
  backdrop-filter: blur(7px);
  background-color: rgba(255,255,255, 0.6);
  /* background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 100%
  ); */
`

export const BackArrow = styled.span`
  margin: 0;
  font-weight: bold;
  pointer-events: all;
  cursor: pointer;
  display: inline-block;
  margin: 0 -0.5rem;
`

export const Separator = styled.span`
  opacity: 0.2;
  display: inline-block;
  margin: 0 0.25rem;
`

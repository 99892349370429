import styled from 'styled-components'
import { Checkbox } from 'antd'

export const ItemLayout = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  color: ${({ bought, theme }) => (bought ? theme.colors.gray : 'black')};
  text-decoration: ${({ bought }) => (bought ? 'line-through' : 'none')};
  cursor: pointer;
  user-select: none;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  }
`

export const BoughtCheckbox = styled(Checkbox)`
  transform: scale(1.5);
`

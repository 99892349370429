import React from 'react'
import PropTypes from 'prop-types'
import AddToListInput from '../AddToListInput'
import { ListContainer, ListItemsContainer } from './List.styled'
import {
  addItemToList,
  changeItemBoughtState,
  clearList,
} from '../../firebaseServices/listManagement'
import ListItem from '../ListItem'
import { Button, Empty } from 'antd'
import { useTranslation } from 'react-i18next'

const List = ({ listData }) => {
  const { t } = useTranslation()
  const addToList = (item) => {
    addItemToList(listData.key, item)
  }

  const handleBoughtChange = (itemKey, newVal) => {
    changeItemBoughtState(listData.key, itemKey, newVal)
  }

  const items = Object.entries(listData.items || {})
    .map(([key, item]) => ({
      key,
      ...item,
    }))
    .sort((a, b) => (a.bought > b.bought ? 1 : -1))

  const hasBoughtItems = items.some((item) => item.bought)

  return (
    <ListContainer>
      {items.length ? (
        <ListItemsContainer>
          {items.map(({ key, item, bought }) => (
            <ListItem
              key={key}
              item={item}
              bought={bought}
              onChange={(newValue) => handleBoughtChange(key, newValue)}
            />
          ))}
          {hasBoughtItems && (
            <Button danger onClick={() => clearList(listData.key)}>
              {t('deleteBoughtItems')}
            </Button>
          )}
        </ListItemsContainer>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('emptyList')}
        />
      )}
      <AddToListInput onSend={addToList} />
    </ListContainer>
  )
}

export default List

List.propTypes = {
  listData: PropTypes.object,
}

List.defaultProps = {
  listData: {},
}

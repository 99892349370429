import styled from 'styled-components'
import FlipMove from 'react-flip-move'

export const InviteUsersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
`
export const AnimatedContainer = styled(FlipMove)`
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
`

import styled from 'styled-components'
import FlipMove from 'react-flip-move'

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  // padding-bottom: 6rem;
`
export const ListItemsContainer = styled(FlipMove)`
  padding-top: 1rem;
  display: flex;
  flex-flow: column;
`

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import Title from '../../components/Title'
import { Layout, ListHeader } from './EditList.styled'
import useSubscribeToList from '../../firebaseServices/listManagement/useSubscribeToList'
import ListSettings from '../../components/ListSettings'
import { getUsersInfo } from '../../firebaseServices/authentication'
import AvatarGroup from '../../components/AvatarGroup'
import List from '../../components/List'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const EditList = () => {
  const [usersInfo, setUsersInfo] = useState([])
  const { listId } = useParams()
  const { t } = useTranslation()
  const { list, loadingList } = useSubscribeToList(listId)
  const listIsLoaded = !!Object.keys(list).length

  useEffect(() => {
    const fetchUsersInfo = async () => {
      const users = await getUsersInfo(Object.keys(list.members || {}))

      setUsersInfo(users)
    }

    fetchUsersInfo()
  }, [list])

  return (
    <Layout>
      <Title text={t('list')} useBackButton />
      {listIsLoaded && (
        <div>
          <ListHeader>
            {list.name}
            <ListSettings list={list} />
            {usersInfo.length > 1 && <AvatarGroup users={usersInfo} />}
          </ListHeader>
          <Spin
            spinning={loadingList && !listIsLoaded}
            indicator={<LoadingOutlined spin />}
          >
            <List listData={list} />
          </Spin>
        </div>
      )}
    </Layout>
  )
}

export default EditList

import React, { useState } from 'react'
import { version } from '../../../package.json'
import { Menu, Dropdown, Badge, Modal, Select } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import {
  MenuItem,
  UserAvatar,
  ModalTitle,
  ModalContent,
  AppTitle,
  AppVersion,
} from './UserMenu.styled'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import AcceptInvitationsModal from '../AcceptInvitationsModal'

import { auth } from '../../firebaseServices/firebase.config.js'
import useSubscribeToInvitations from '../../firebaseServices/listManagement/useSubscribeToInvitations'
import { logOut } from '../../firebaseServices/authentication/googleAuthentication'
import { LanguageSelector } from '../ListSettings/ListSettings.styled'

const REM = 16
const avatarSize = REM * 3

const UserMenu = () => {
  const [optionsModalVisible, setOptionsModalVisible] = useState(false)
  const user = auth.currentUser
  const { t, i18n } = useTranslation()
  const userAvatar = user?.photoURL
  const invitations = useSubscribeToInvitations()
  const [invitationsVisible, setInvitationsVisible] = useState(false)

  const openLogoutModal = () =>
    Modal.confirm({
      title: t('logOutConfirm'),
      icon: <ExclamationCircleOutlined />,
      okText: t('logOut'),
      cancelText: t('cancel'),
      onOk: logOut,
      centered: true,
    })

  const openSettingsModal = () => {
    setOptionsModalVisible(true)
  }

  const menuItems = [
    {
      text: t('settings'),
      action: openSettingsModal,
    },
    {
      text: t('invitations'),
      count: invitations.length,
      action: () => setInvitationsVisible(true),
    },
    {
      danger: true,
      text: t('logOut'),
      action: openLogoutModal,
    },
  ]

  const handleItemClick = ({ key }) => menuItems[key].action?.()

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang)
  }

  const menu = (
    <Menu onClick={handleItemClick}>
      {menuItems.map((item, index) => (
        <MenuItem danger={item.danger} key={index} icon={item.icon}>
          {item.text} <Badge count={item.count} />
        </MenuItem>
      ))}
    </Menu>
  )

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <Badge count={invitations.length}>
          {userAvatar ? (
            <UserAvatar src={userAvatar} size={avatarSize} />
          ) : (
            <UserAvatar icon={<UserOutlined />} size={avatarSize} />
          )}
        </Badge>
      </Dropdown>
      <AcceptInvitationsModal
        visible={invitationsVisible}
        onClose={() => setInvitationsVisible(false)}
        invitations={invitations}
      />
      <Modal
        centered
        title={<ModalTitle>{t('settings')}</ModalTitle>}
        visible={optionsModalVisible}
        onCancel={() => setOptionsModalVisible(false)}
        footer={null}
      >
        <ModalContent>
          <img src='logo.png' width='25%' alt='logo' />
          <AppTitle>
            Grocery<b>Together</b>
            <AppVersion>{version}</AppVersion>
          </AppTitle>
          <LanguageSelector>
            {t('language')}
            <Select defaultValue={i18n.language} onChange={changeLanguage}>
              <Select.Option value='en'>English</Select.Option>
              <Select.Option value='es'>Español</Select.Option>
            </Select>
          </LanguageSelector>
          <AppVersion>
            © Daniel Valls Mestre, {new Date().getFullYear()}
          </AppVersion>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UserMenu

UserMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      danger: PropTypes.bool,
      text: PropTypes.string,
      action: PropTypes.func,
    })
  ),
}

UserMenu.defaultProps = {
  items: [],
}

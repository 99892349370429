import { get, ref, update } from '@firebase/database'
import { db } from '../firebase.config'

export const updateUserDataInDB = async ({ uid, displayName, photoURL }) => {
  await update(ref(db, `users/${uid}`), { uid, displayName, photoURL })
}

export const getUserInfo = async (uid) => {
  const snapshot = await get(ref(db, `users/${uid}`))

  if (snapshot.exists()) {
    const { displayName, photoURL, uid } = snapshot.val()
    return { displayName, photoURL, uid }
  } else {
    return null
  }
}

export const getUsersInfo = async (uids) => {
  return (
    await Promise.all(uids.map(async (uid) => await getUserInfo(uid)))
  ).filter((user) => user)
}

import React, { useState } from 'react'
import { Form, Input, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { SumbitButtonContainer } from './CreateListForm.styled'
import InviteUsersInterface from '../InviteUsersInterface'

const CreateListForm = ({ onSubmit }) => {
  const [listName, setListName] = useState('')
  const [usersToInvite, setUsersToInvite] = useState([])
  const { t } = useTranslation()

  const nameValidator = (rule, value) =>
    value.trim()
      ? Promise.resolve()
      : Promise.reject(new Error(t('requiredField')))

  const handleAddUserToInvite = (emails) => {
    setUsersToInvite(emails)
  }

  const handleRemoveUserToInvite = (emailToRemove) => {
    setUsersToInvite((emails) =>
      emails.filter((email) => email !== emailToRemove)
    )
  }

  return (
    <Form onFinish={() => onSubmit(listName, usersToInvite)}>
      <Form.Item
        label={t('listName')}
        name='listName'
        rules={[
          {
            required: true,
            validator: nameValidator,
            message: t('requiredField'),
          },
        ]}
      >
        <Input
          placeholder={t('listNamePlaceholder')}
          onChange={({ target }) => setListName(target.value)}
          size='large'
          autoFocus
          allowClear
        />
      </Form.Item>

      <Form.Item label={t('addYourFriends')}>
        <InviteUsersInterface
          onUserAdded={handleAddUserToInvite}
          onUserRemoved={handleRemoveUserToInvite}
        />
      </Form.Item>

      <Form.Item style={{ position: 'fixed', bottom: 0, right: '2rem' }}>
        <SumbitButtonContainer>
          <Button type='primary' size='large' htmlType='submit'>
            {t('create')}
          </Button>
        </SumbitButtonContainer>
      </Form.Item>
    </Form>
  )
}

export default CreateListForm

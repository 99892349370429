import { useEffect, useState } from 'react'
import { db } from '../firebase.config'
import { onValue, onChildRemoved, ref } from '@firebase/database'

const useSubscribeToUserLists = (userKey) => {
  const [lists, setLists] = useState([])
  const [loadingLists, setLoadingLists] = useState(false)

  useEffect(() => {
    setLoadingLists(true)
    const databaseRef = ref(db, `users/${userKey}/lists`)

    const unsubscribeOnValue = onValue(databaseRef, (snapshot) => {
      if (snapshot.exists()) {
        const newLists = Object.entries(snapshot.val()).map(([key, name]) => ({
          key,
          name,
        }))

        setLists(newLists)
      }
      setLoadingLists(false)
    })

    const unsubscribeOnChildRemove = onChildRemoved(databaseRef, (snapshot) => {
      if (snapshot.exists()) {
        setLists((lists) => lists.filter(({ key }) => key !== snapshot.key))
      }
    })

    return () => {
      unsubscribeOnValue()
      unsubscribeOnChildRemove()
    }
  }, [userKey])

  return { lists, loadingLists }
}

export default useSubscribeToUserLists

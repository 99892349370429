import styled from 'styled-components'
import FlipMove from 'react-flip-move'

export const HomeLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content 1fr;
  grid-gap: 2rem;
`

export const Title = styled.h1`
  margin: 0;
  font-weight: bold;
  position: sticky;
  top: 2rem;
`

export const ListsContainer = styled(FlipMove).attrs(() => ({
  enterAnimation: 'fade',
}))`
  display: flex;
  flex-flow: column;
  gap: 1rem;
`

export const storeUser = (user) => {
  return {
    type: 'set',
    payload: user,
  }
}

export const clearUser = () => {
  return {
    type: 'clear',
  }
}

import React from 'react'

import { CardContainer, Icon, Title } from './CreateListCard.styled'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const Card = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const redirectToCreateList = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    history.push('/createlist')
  }
  return (
    <CardContainer onClick={redirectToCreateList}>
      <Icon />
      <Title>{t('createList')}</Title>
    </CardContainer>
  )
}

export default Card

import React, { useEffect, useState } from 'react'
import {
  ControlsPanel,
  LoginLayout,
  Logo,
  LogoContainer,
  Title,
} from './Login.styled'
import LoginWithGoogleButton from '../../components/LoginWithGoogleButton'
import { Divider } from 'antd'
import Spinner from '../../components/Spinner'

const LoginPage = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000)
  }, [])

  return (
    <LoginLayout>
      <LogoContainer>
        <Logo src='logo.png' />
        <Title>
          Grocery<span>Together</span>
        </Title>
      </LogoContainer>
      <Divider />
      <ControlsPanel>
        <Spinner loading={loading} contained>
          <LoginWithGoogleButton />
        </Spinner>
      </ControlsPanel>
    </LoginLayout>
  )
}

export default LoginPage

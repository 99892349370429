import { auth, db } from '../firebase.config'
import { ref, onValue } from '@firebase/database'
import { useEffect, useState } from 'react'

const useSubscribeToInvitations = () => {
  const [invitations, setInvitations] = useState([])
  useEffect(() => {
    let unsubscribe = null

    if (auth.currentUser) {
      const { email } = auth.currentUser
      const encodedEmail = window.btoa(email)
      unsubscribe = onValue(
        ref(db, `invitations/${encodedEmail}`),
        (snapshot) => {
          if (snapshot.exists()) {
            const mappedValue = Object.entries(snapshot.val()).map(
              ([key, invitation]) => ({ key, ...invitation })
            )
            setInvitations(mappedValue)
          } else {
            setInvitations([])
          }
        }
      )
    }

    return () => {
      unsubscribe?.()
    }
  }, [])

  return invitations
}

export default useSubscribeToInvitations

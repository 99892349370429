import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEN from './locales/en/translation.json'
import translationES from './locales/es/translation.json'

const lang = localStorage.getItem('lang')

i18n.use(initReactI18next).init({
  fallbackLng: lang || 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: translationEN,
    },
    es: {
      translation: translationES,
    },
  },
})

export default i18n

import styled from 'styled-components'

export const ModalTitle = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
`

export const ModalContentLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  width: 100%;
`

export const FieldLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto;
  align-items: center;
  grid-gap: 0.5rem;
  width: 100%;
`

export const FieldTitle = styled.div`
  font-weight: bold;
`

export const LanguageSelector = styled.label`
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

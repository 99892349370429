import styled from 'styled-components'

export const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-gap: 2rem;
`

export const SumbitButtonContainer = styled.div`
  text-align: right;
`

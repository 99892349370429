import styled from 'styled-components'

export const CardContainer = styled.div`
  background-color: white;
  border: 2px solid ${({ theme }) => theme.colors.gray};
  border-radius: 10px;
  width: calc(100vw - 4rem);
  max-width: calc(100vw - 4rem);
  display: grid;
  grid-template-columns: 1fr min-content;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
`

export const ListName = styled.div`
  padding: 1rem 1rem 1rem 2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
`

export const Controls = styled.div`
  display: flex;
`

export const Control = styled.button`
  border: none;
  outline: none;
  background-color: white;
  padding: 0.75rem;
  font-size: 1.1rem;
`

export const BuyControl = styled(Control)`
  color: #ff5656;
`

export const EditControl = styled(Control)`
  color: ${({ theme }) => theme.colors.primary};
`

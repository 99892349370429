import styled from 'styled-components'
import { PlusCircleOutlined } from '@ant-design/icons'

export const CardContainer = styled.button`
  width: 100%;
  padding: 1.5rem 2rem 1rem 2rem;
  border: 2px dashed ${({ theme }) => theme.colors.gray};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray};
  gap: 0.5rem;
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.bounce};

  &:active {
    transform: scale(0.95);
  }
`

export const Icon = styled(PlusCircleOutlined)`
  font-size: 3rem;
`

export const Title = styled.div`
  font-size: 1rem;
`

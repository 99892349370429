import styled from 'styled-components'
import { Button, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

export const FloatingContainer = styled.div`
  position: sticky; // absolute
  bottom: 2rem; // 0
`

export const AddIcon = styled(PlusOutlined)`
  font-size: 1.25rem;
`

export const FloatingButton = styled(Button).attrs(() => ({
  shape: 'circle',
  type: 'primary',
  icon: <AddIcon />,
}))`
  position: sticky; // absolute
  top: calc(100% - 3.5rem -2rem); // --
  right: 2rem;
  left: calc(100% - 3.5rem - 2rem); // --
  bottom: 2rem;
  width: 3.5rem;
  height: 3.5rem;
  font-size: 3rem;
`

export const FloatInput = styled(Input.Search)`
  position: sticky;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
  width: 100%;
`

import React from 'react'
import { TitleText, BackArrow, Separator, TitleMask } from './Title.styled'
import PropTypes from 'prop-types'
import { CaretLeftFilled } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

const Title = ({ useBackButton, text }) => {
  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  return (
    <>
      <TitleText>
        {useBackButton && (
          <>
            <BackArrow onClick={goBack}>
              <CaretLeftFilled />
            </BackArrow>
            <Separator>/</Separator>
          </>
        )}
        {text}
      </TitleText>
      <TitleMask />
    </>
  )
}

export default Title

Title.propTypes = {
  useBackButton: PropTypes.bool,
  text: PropTypes.string,
}

Title.defaultProps = {
  useBackButton: false,
  text: '',
}

// import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { auth } from '../firebase.config'
import { onAuthStateChanged } from '@firebase/auth'

const useAuthenticatedUser = () => {
  const [authenticatedUser, setAuthenticatedUser] = useState(auth.currentUser)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticatedUser(user)
      } else {
        setAuthenticatedUser(null)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return authenticatedUser
}

export default useAuthenticatedUser

import { Menu } from 'antd'
import Avatar from '../Avatar'
import styled from 'styled-components'

export const MenuItem = styled(Menu.Item)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
`

export const UserAvatar = styled(Avatar)`
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
`

export const ModalTitle = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
`

export const ModalContent = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 0.5rem;
`

export const AppTitle = styled.div`
  text-align: center;
`

export const AppVersion = styled.div`
  font-size: 0.6rem;
`

import React from 'react'
import { Avatar } from 'antd'

const UserAvatar = ({ src, children, className, size }) => {
  return (
    <Avatar
      size={size}
      className={className}
      src={<img src={src} referrerPolicy='no-referrer' alt='user-logo' />}
    >
      {children}
    </Avatar>
  )
}

export default UserAvatar

const theme = {
  colors: {
    primary: '#1890FF',
    error: '#FF4D4F',
    lightGray: '#EFEFEF',
    gray: '#C2C2C2',
  },

  transitions: {
    bounce: '0.3s cubic-bezier(0, 0.5, 0.2, 2)',
  },
}

export default theme

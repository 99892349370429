import React, { useEffect, useRef, useState } from 'react'
import FlipMove from 'react-flip-move'
import { useTranslation } from 'react-i18next'

import {
  FloatingContainer,
  FloatingButton,
  FloatInput,
} from './AddToListInput.styled'
import getRandomStuff from './randomStuff'

const AddToListInput = ({ onSend }) => {
  const [displayInput, setDisplayInput] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const floatingRef = useRef()
  const { t } = useTranslation()

  const handleAddButton = (value) => {
    const trimmedValue = value.trim()
    setInputValue(trimmedValue)
    if (trimmedValue) {
      onSend(trimmedValue)
      setInputValue('')
    }
  }

  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      const clickedOutside =
        target !== floatingRef.current && !floatingRef.current.contains(target)
      if (clickedOutside) {
        setDisplayInput(false)
      }
    }

    window.addEventListener('click', handleClickOutside)

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  })

  return (
    <FloatingContainer ref={floatingRef}>
      <FlipMove>
        {displayInput && (
          <FloatInput
            autoFocus
            allowClear
            value={inputValue}
            onChange={({ target }) => setInputValue(target.value)}
            placeholder={t(`rs-${getRandomStuff()}`)}
            enterButton={t('add')}
            size='large'
            onSearch={handleAddButton}
          />
        )}

        {!displayInput && (
          <FloatingButton onClick={() => setDisplayInput(true)} />
        )}
      </FlipMove>
    </FloatingContainer>
  )
}

export default AddToListInput

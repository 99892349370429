import React, { forwardRef } from 'react'
import {
  CardContainer,
  ListName,
  Controls,
  EditControl,
} from './ListCard.styled'
import { EditOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const ListCard = forwardRef(({ name, onEdit }, ref) => {
  const handleEdit = () => {
    onEdit?.()
  }
  return (
    <CardContainer ref={ref}>
      <ListName onClick={handleEdit}>{name}</ListName>
      <Controls>
        <EditControl onClick={handleEdit}>
          <EditOutlined />
        </EditControl>
      </Controls>
    </CardContainer>
  )
})

export default ListCard

ListCard.propTypes = {
  name: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
}

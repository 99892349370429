import styled from 'styled-components'

export const SpinnerMask = styled.div`
  position: ${({ contained }) => (contained ? 'unset' : 'fixed')};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: ${({ contained }) => (contained ? 'none' : 'blur(2px)')};
`

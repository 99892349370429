const randomStuff = [
  'intergalacticShip',
  'fluxCapacitor',
  'unicorns',
  'cursedSword',
  'gardenGnomes',
  'timeMachine',
  'martianPotatos',
  'cosmicVegetables',
  'flerkenFood',
  'thorHammer',
  'necronomicon',
  'manticoreVenom',
]

const getRandomStuff = () => {
  return randomStuff[Math.floor(Math.random() * randomStuff.length)]
}

export default getRandomStuff

import React, { useState } from 'react'
import { Button, Modal, Input, message, Divider } from 'antd'
import { SettingOutlined, SaveOutlined, DeleteFilled } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import {
  ModalTitle,
  ModalContentLayout,
  FieldLayout,
  FieldTitle,
} from './ListSettings.styled'
import {
  deleteList,
  renameList,
  inviteUser,
  removeUser,
} from '../../firebaseServices/listManagement'
import { useHistory } from 'react-router'
import InviteUsersInterface from '../InviteUsersInterface'
import { auth } from '../../firebaseServices/firebase.config.js'

const { Search: InputWithButton } = Input

const ListSettings = ({ list }) => {
  const [modalVisibility, setModalVisibility] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()
  const user = auth.currentUser

  const memberEmails = Object.values(list.members || {}).concat(
    Object.values(list.usersInvited || {})
  )

  const confirmDelete = () =>
    Modal.confirm({
      centered: true,
      title: t('deleteConfirm'),
      okText: t('yesDelete'),
      cancelText: t('cancel'),
      onOk: async () => {
        await deleteList(list)
        history.go(-1)
        message.success(t('listDeleted'))
      },
    })

  const rename = async (newName) => {
    await renameList(list.key, newName)
    message.success(t('listNameUpdated'))
  }

  const handleUserAdded = async (users) => {
    const usersToAdd = users.filter((user) => !memberEmails.includes(user))

    await Promise.all(
      usersToAdd.map(async (email) => {
        await inviteUser(list.key, list.name, email, {
          uid: user.uid,
          photoURL: user.photoURL,
          displayName: user.displayName,
        })
      })
    )
  }

  const handleUserRemoved = async (userEmail) => {
    const userKeyToDelete = Object.entries(list.members || {}).filter(
      ([key, email]) => email === userEmail
    )[0]?.[0]

    await removeUser(list.key, userKeyToDelete, userEmail)
  }

  return (
    <>
      <Button
        type='dashed'
        shape='circle'
        icon={<SettingOutlined />}
        size='large'
        onClick={() => setModalVisibility(true)}
      />
      <Modal
        visible={modalVisibility}
        footer={null}
        title={<ModalTitle>{t('listSettings')}</ModalTitle>}
        onCancel={() => setModalVisibility(false)}
        centered
      >
        <ModalContentLayout>
          <FieldLayout>
            <FieldTitle>{t('listName')}</FieldTitle>
            <InputWithButton
              size='large'
              enterButton={<SaveOutlined />}
              defaultValue={list.name}
              onSearch={rename}
            />
          </FieldLayout>

          <FieldLayout>
            <FieldTitle>{t('addYourFriends')}</FieldTitle>
            <InviteUsersInterface
              initialUsers={memberEmails}
              onUserAdded={handleUserAdded}
              onUserRemoved={handleUserRemoved}
            />
          </FieldLayout>

          <Divider />

          <Button
            type='primary'
            danger
            ghost
            icon={<DeleteFilled />}
            onClick={confirmDelete}
          >
            {t('deleteList')}
          </Button>
        </ModalContentLayout>
      </Modal>
    </>
  )
}

export default ListSettings

import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { SpinnerMask } from './Spinner.styled'

const Spinner = ({ loading, contained, children }) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: contained ? 28 : '3rem' }} spin />
  )
  return (
    <SpinnerMask contained={contained}>
      <Spin spinning={loading} indicator={antIcon}>
        {children}
      </Spin>
    </SpinnerMask>
  )
}

export default Spinner

import { Route, BrowserRouter as Router } from 'react-router-dom'
import { CustomSwitch } from './CustomSwitch'

import routes from './routes'

const ProtectedRoutes = () => (
  <Router>
    <CustomSwitch>
      {routes.map(({ component: Component, path, exact }) => (
        <Route path={`/${path}`} key={path} exact={exact}>
          <Component />
        </Route>
      ))}
    </CustomSwitch>
  </Router>
)

export default ProtectedRoutes

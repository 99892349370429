import { useState, useEffect } from 'react'
import { db } from '../firebase.config'
import { onValue, ref } from '@firebase/database'

const useSubscribeToList = (listKey) => {
  const [list, setList] = useState({})
  const [loadingList, setLoadingList] = useState(false)

  useEffect(() => {
    setLoadingList(true)
    const unsubscribe = onValue(ref(db, `lists/${listKey}`), (snapshot) => {
      if (snapshot.exists()) {
        const value = {
          key: listKey,
          ...snapshot.val(),
        }
        setList(value)
      } else {
        setList({})
      }

      setLoadingList(false)
    })

    return () => {
      unsubscribe()
    }
  }, [listKey])

  return { list, loadingList }
}

export default useSubscribeToList

import { Route, Redirect } from 'react-router-dom'
import { PrivateSectionLayout, AvatarSection } from './PrivateRoute.styled'
import UserMenu from '../components/UserMenu'

const PrivateRoute = ({ children, isAuthenticated, ...rest }) => {
  return (
    <PrivateSectionLayout>
      <AvatarSection>
        <UserMenu />
      </AvatarSection>
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        }
      />
    </PrivateSectionLayout>
  )
}

export default PrivateRoute

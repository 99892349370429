import useAuthenticatedUser from './firebaseServices/authentication/useAuthenticatedUser'
import PublicRoute from './Routing/PublicRoute'
import PrivateRoute from './Routing/PrivateRoute'
import ProtectedRoutes from './Routing/ProtectedRoutes'
import { BrowserRouter as Router } from 'react-router-dom'
import { AnimatedSwitch } from 'react-router-transition'
import LoginPage from './Pages/Login'
import 'antd/dist/antd.css'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import { useEffect } from 'react'
import { storeUser, clearUser } from './redux/userActions'
import { useDispatch } from 'react-redux'
import { updateUserDataInDB } from './firebaseServices/authentication'

const App = () => {
  const user = useAuthenticatedUser()
  const dispatch = useDispatch()

  const isAuthenticated = !!user

  useEffect(() => {
    if (user) {
      dispatch(storeUser(user))
      updateUserDataInDB(user)
    } else {
      dispatch(clearUser())
    }
  }, [user, dispatch])

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className='switch-wrapper'
        >
          <PublicRoute path='/login' isAuthenticated={isAuthenticated}>
            <LoginPage />
          </PublicRoute>
          <PrivateRoute path='/' isAuthenticated={isAuthenticated}>
            <ProtectedRoutes />
          </PrivateRoute>
        </AnimatedSwitch>
      </ThemeProvider>
    </Router>
  )
}

export default App

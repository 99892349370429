import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import {
  InviteUsersContainer,
  AnimatedContainer,
} from './InviteUsersInterface.styled'
import { Input, Button } from 'antd'
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { auth } from '../../firebaseServices/firebase.config.js'

const { Search } = Input

const InviteUsersInterface = ({ onUserAdded, onUserRemoved, initialUsers }) => {
  const [typingEmail, setTypingEmail] = useState('')
  const [usersAdded, setUsersAdded] = useState(initialUsers)
  const { t } = useTranslation()
  const userEmail = auth.currentUser.email

  const addUser = (email) => {
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    if (email && emailRegex.test(email)) {
      if (!usersAdded.includes(email)) {
        const newUsersList = [...usersAdded, email]
        setUsersAdded(newUsersList)
        setTypingEmail('')
        onUserAdded?.(newUsersList)
      }
    }
  }

  const removeUser = (emailToDelete) => {
    const filteredMails = usersAdded.filter((email) => email !== emailToDelete)

    setUsersAdded(filteredMails)
    onUserRemoved?.(emailToDelete)
  }

  const handleEnter = (e) => {
    e.preventDefault()
    addUser(typingEmail)
  }

  return (
    <InviteUsersContainer>
      <div>
        <Search
          value={typingEmail}
          onChange={({ target }) => setTypingEmail(target.value?.toLowerCase())}
          placeholder={t('writeFriendsMail')}
          size='large'
          onSearch={addUser}
          enterButton={<PlusCircleOutlined />}
          onPressEnter={handleEnter}
        />
      </div>
      <AnimatedContainer
        appearAnimation='fade'
        enterAnimation='fade'
        leaveAnimation='fade'
      >
        {usersAdded.map((mail) => (
          <div key={`user-email-${mail}`}>
            <Button
              type='dashed'
              shape='round'
              danger
              ghost
              icon={<DeleteOutlined />}
              onClick={() => removeUser(mail)}
              disabled={mail === userEmail}
            >
              {mail}
            </Button>
          </div>
        ))}
      </AnimatedContainer>
    </InviteUsersContainer>
  )
}

export default InviteUsersInterface

InviteUsersInterface.propTypes = {
  onUserAdded: PropTypes.func,
  initialUsers: PropTypes.array,
}

InviteUsersInterface.defaultProps = {
  initialUsers: [],
}

import React from 'react'
import PropTypes from 'prop-types'
import { Modal, List, Avatar, Button, Empty } from 'antd'
import {
  UserOutlined,
  DeleteFilled,
  CheckCircleFilled,
} from '@ant-design/icons'
import { ModalTitle } from './AcceptInvitationsModal.styled'
import { useTranslation } from 'react-i18next'
import {
  acceptInvitation,
  rejectInvitation,
} from '../../firebaseServices/listManagement'
import { auth } from '../../firebaseServices/firebase.config'

const AcceptInvitationsModal = ({ visible, onClose, invitations }) => {
  const { t } = useTranslation()
  const user = auth.currentUser

  const handleAcceptInvitation = async (listKey, listName) => {
    await acceptInvitation(user.email, user.uid, listKey, listName)
    if (!invitations.length) onClose()
  }

  const handleRejectInvitation = async (listKey) => {
    await rejectInvitation(user.email, listKey)
    if (!invitations.length) onClose()
  }

  return (
    <Modal
      centered
      visible={visible}
      onCancel={onClose}
      title={<ModalTitle>{t('invitations')}</ModalTitle>}
      footer={null}
    >
      {invitations.length ? (
        <List
          dataSource={invitations}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  ghost
                  type='danger'
                  icon={<DeleteFilled />}
                  shape='circle'
                  onClick={() => handleRejectInvitation(item.listKey)}
                />,
                <Button
                  ghost
                  type='primary'
                  icon={<CheckCircleFilled />}
                  shape='circle'
                  onClick={() =>
                    handleAcceptInvitation(item.listKey, item.listName)
                  }
                />,
              ]}
            >
              <List.Item.Meta
                avatar={
                  item.invitedBy.photoURL ? (
                    <Avatar src={item.invitedBy.photoURL} />
                  ) : (
                    <Avatar icon={<UserOutlined />} />
                  )
                }
                title={<b>{item.invitedBy.displayName}</b>}
                description={item.listName}
              />
            </List.Item>
          )}
        />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('nothingHere')}
        />
      )}
    </Modal>
  )
}

export default AcceptInvitationsModal

AcceptInvitationsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  invitations: PropTypes.array.isRequired,
}

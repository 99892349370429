import styled from 'styled-components'

export const LoginLayout = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr min-content min-content;
  justify-items: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 2rem;
`

export const ControlsPanel = styled.div`
  display: flex;
  flex-flow: column;
  gap: 2rem;
`

export const LogoContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1rem;
  align-items: center;
`

export const Title = styled.div`
  font-weight: 400;
  font-size: 1.25rem;
  font-family: 'Inter';
  text-align: center;
  span {
    font-weight: 700;
  }
`

export const Logo = styled.img`
  width: 50%;
`

import React from 'react'
import { Layout } from './CreateList.styled'
import Title from '../../components/Title'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { saveNewList } from '../../firebaseServices/listManagement'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import CreateListForm from '../../components/CreateListForm'

const CreateListPage = () => {
  const user = useSelector((state) => state.user)
  const history = useHistory()
  const { t } = useTranslation()

  const createList = async (listName, usersToInvite) => {
    await saveNewList(user, listName.trim(), usersToInvite)
    message.success(t('listCreated'))
    history.goBack()
  }

  return (
    <Layout>
      <Title useBackButton text={t('newList')}></Title>
      <CreateListForm onSubmit={createList} />
    </Layout>
  )
}

export default CreateListPage

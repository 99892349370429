import styled from 'styled-components'

export const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-gap: 2rem;
  min-height: 100%;
`

export const ListHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  font-weight: bold;
  font-size: 1rem;
  align-items: center;
  grid-gap: 1rem;
`

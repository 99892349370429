import React from 'react'
import PropTypes from 'prop-types'

import { Avatar, Tooltip } from 'antd'
import UserAvatar from '../Avatar'

const AvatarGroup = ({ users }) => {
  const getInitials = (str) =>
    str
      .split(' ')
      .map((word) => word[0])
      .join('')

  return (
    <Avatar.Group maxCount={6}>
      {users.map(({ photoURL, displayName }) => (
        <Tooltip title={displayName} placement='top' key={Math.random()}>
          {photoURL ? (
            <UserAvatar src={photoURL} size='large' />
          ) : (
            <UserAvatar size='large'>{getInitials(displayName)}</UserAvatar>
          )}
        </Tooltip>
      ))}
    </Avatar.Group>
  )
}

export default AvatarGroup

AvatarGroup.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      photoURL: PropTypes.string,
    })
  ).isRequired,
}

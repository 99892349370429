import { db } from '../firebase.config'
import { push, ref, set, get, update } from '@firebase/database'

export const saveNewList = async (user, name, usersToInvite) => {
  const members = {}
  members[user.uid] = user.email

  const { key: listKey } = await push(ref(db, 'lists'), {
    name,
    members,
    createdBy: user.uid,
  })

  await set(ref(db, `users/${user.uid}/lists/${listKey}`), name)

  await Promise.all(
    usersToInvite.map(async (mail) => {
      await inviteUser(listKey, name, mail, {
        uid: user.uid,
        photoURL: user.photoURL,
        displayName: user.displayName,
      })
    })
  )
}

export const deleteList = async (list) => {
  const { members, key, usersInvited } = list
  const membersKeys = Object.keys(members || {})
  const emailsInvited = Object.values(usersInvited || {})

  await Promise.all(
    emailsInvited.map(async (email) => {
      const encodedMail = window.btoa(email)
      await set(ref(db, `invitations/${encodedMail}/${key}`), null)
    })
  )

  await Promise.all(
    membersKeys.map(async (memberKey) => {
      await set(ref(db, `users/${memberKey}/lists/${key}`), null)
    })
  )
  await set(ref(db, `lists/${key}`), null)
}

export const renameList = async (listKey, newName) => {
  await set(ref(db, `lists/${listKey}/name`), newName)
}

export const inviteUser = async (listKey, listName, userEmail, invitedBy) => {
  const snapshot = await get(ref(db, `lists/${listKey}/members`))

  if (snapshot.exists()) {
    const membersEmails = Object.values(snapshot.val())

    if (!membersEmails.includes(userEmail)) {
      await push(ref(db, `lists/${listKey}/usersInvited`), userEmail)
      return await set(
        ref(db, `invitations/${window.btoa(userEmail)}/${listKey}`),
        {
          listKey,
          listName,
          invitedBy,
        }
      )
    }
  }

  return Promise.resolve()
}

const removeInvitationFromList = async (listKey, userEmail) => {
  const snapshot = await get(ref(db, `lists/${listKey}/usersInvited`))
  if (snapshot.exists()) {
    const usersInvited = Object.entries(snapshot.val())
    const keyToDelete = usersInvited.find(
      ([key, mail]) => mail === userEmail
    )?.[0]

    keyToDelete &&
      (await set(ref(db, `lists/${listKey}/usersInvited/${keyToDelete}`), null))
  }
}

export const removeUser = async (listKey, userKey, userEmail) => {
  await removeInvitationFromList(listKey, userEmail)
  await set(ref(db, `invitations/${window.btoa(userEmail)}/${listKey}`), null)
  userKey && (await set(ref(db, `users/${userKey}/lists/${listKey}`), null))
  await set(ref(db, `lists/${listKey}/members/${userKey}`), null)
}

export const acceptInvitation = async (
  userEmail,
  userKey,
  invitationKey,
  listName
) => {
  const encodedMail = window.btoa(userEmail)
  await removeInvitationFromList(invitationKey, userEmail)

  await set(ref(db, `lists/${invitationKey}/members/${userKey}`), userEmail)
  await set(ref(db, `users/${userKey}/lists/${invitationKey}`), listName)
  await set(ref(db, `invitations/${encodedMail}/${invitationKey}`), null)
}

export const rejectInvitation = async (userEmail, invitationKey) => {
  await removeInvitationFromList(invitationKey, userEmail)

  const encodedMail = window.btoa(userEmail)

  await set(ref(db, `invitations/${encodedMail}/${invitationKey}`), null)
}

export const getInvitations = async (userEmail) => {
  const snapshot = await get(ref(db, `invitations/${window.btoa(userEmail)}`))

  if (snapshot.exists()) {
    return Object.values(snapshot.val())
  } else {
    return []
  }
}

export const addItemToList = async (listKey, item) => {
  await push(ref(db, `lists/${listKey}/items`), { item, bought: false })
}

export const changeItemBoughtState = async (listKey, itemKey, newState) => {
  await update(ref(db, `lists/${listKey}/items/${itemKey}`), {
    bought: newState,
  })
}

export const clearList = async (listKey) => {
  const snapshot = await get(ref(db, `lists/${listKey}/items`))

  if (snapshot.exists()) {
    const value = snapshot.val()
    const boughtItems = Object.entries(value).filter(
      ([key, item]) => item.bought
    )

    await Promise.all(
      boughtItems.map(async ([key]) => {
        await set(ref(db, `lists/${listKey}/items/${key}`), null)
      })
    )
  }
}

export const updateUserListsNames = async (userKey) => {
  const currentSnapshot = await get(ref(db, `users/${userKey}/lists`))

  if (currentSnapshot.exists()) {
    const currentNames = Object.entries(currentSnapshot.val())

    await Promise.all(
      currentNames.map(async ([key, name]) => {
        const listSnapshot = await get(ref(db, `lists/${key}/name`))
        if (listSnapshot.exists()) {
          const listName = listSnapshot.val()

          if (name !== listName) {
            await set(ref(db, `users/${userKey}/lists/${key}`), listName)
          }
        }
      })
    )
  }
}

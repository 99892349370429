const initialState = {
  user: null,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, user: action.payload }
    case 'clear':
      return { ...state, user: null }
    default:
      return state
  }
}

export default userReducer
